<template lang="pug">
Navbar
.padding-top.help
  .help-header.pricing.pb-5
    .fs-4.pricing-title.fw-bold.lh-sm.pb-2.text-center How can we help you?
    .container
      .row.height.d-flex.justify-content-center.align-items-center
        .col-md-6
          .search
            span.input-group-text
              img.ps-3(src="icons/search.svg")
              input.form-control(type='text' placeholder='Search Here' v-model="search")
.container.py-5
  BackHelp
  .row
    .col-md-12.py-5
      template(v-for="help in ContactList")
        .help-title.fs-5.fw-bold.pb-4 {{ help.title }}
        .help-details
          .description
            template(v-for="subhelp in help.description")
              p {{ subhelp.text }}
              .help-image.py-4(v-if="subhelp.imgType === true")
                img.img-fluid(:src="'/images/help/' + subhelp.img")
          .detail-explanation
            template(v-for="exp in help.explanation")
              .explanation-title.fs-5.fw-bold {{ exp.expTitle }}
              .explanation-description
                p {{ exp.expDesc }}
              .explanation-image.py-4(v-if="exp.imgType === true")
                img.img-fluid(:src="'/images/help/' + exp.img")
  BackHelp
Footer
</template>

<script>
import Navbar from '../../../components/TheNavbar';
import Footer from "../../../components/TheFooter";
import BackHelp from "../../../components/BackHelp";
export default {
  name: "UpdateAccount",
  components: { Footer, Navbar, BackHelp },
  data() {
    return {
      ContactList: [
        {
          title: "6.1 Update account",
          description: [
            {
              text: "1. To edit account details, go to the “Account” at the bottom tab. Tap the “+Manage account” row",
              imgType: true,
              img: "639.Account – 2.png"
            },
            {
              text: "2. You will see the “Manage account” page. You can edit your account name, mobile number or company name. The domain name will update automatically according to the company name you have. Subject to availability. ",
              imgType: true,
              img: "640.Manage account.png",
            },
            {
              text: "3. Last but not least, you can change your 6-digit PIN on this page too. ",
              imgType: false,
            },
          ],
        },
      ],
    };
  },
};
</script>
